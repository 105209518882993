

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { CartItemData } from '../../../../../contexts'
import { CardCheckProps } from '../../../../../dsl/molecules/CardCheck'

import { toImageProps } from '../../../../shared/support'
import { translateToCartItem } from '../../../molecules/CartItem/CartItem.helpers'

import { SingleCartItem } from '../../../organisms/SingleCartItem'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Gifts>({
  name: 'Gifts',
  components: {
    SingleCartItem
  },
  created () {
    /**
     * @inheritDoc
     */
    this.composeGiftCards(this.gifts)
  }
})
export class Gifts extends Vue {
  /**
   * FIXME AnyObject
   */
  @Prop({ type: Array, required: true })
  public gifts!: CartItemData

  public selectedGift: string = ''

  public giftResign: string = ''

  public composedGiftCards!: CartItemData & CardCheckProps

  public get giftCards (): CartItemData & CardCheckProps {
    return this.composedGiftCards
  }

  public toImageProps = toImageProps

  /**
   * Resign from gift by deselecting all previously selected gifts
   * @param value
   */
  public onGiftResign (value: string): void {
    this.giftResign = value
    this.selectedGift = ''

    this.$emit('resignFromGift')
  }

  /**
   * Update selected gift value
   * @param value
   */
  public onGiftSelect (value: string): void {
    this.selectedGift = value
    this.giftResign = ''

    this.$emit('selectGift', { id: value })
  }

  /**
   * FIXME AnyObject
   */
  protected composeGiftCards (gifts: AnyObject): void {
    this.composedGiftCards = gifts.map((gift: CartItemData, index: number) => {
      return {
        isDisabled: false,
        id: gift.uid,
        multiple: false,
        name: `gift-${index}`,
        required: false,
        value: gift.product.id,
        data: { ...gift }
      }
    })
  }

  public translateToCartItem = translateToCartItem
}

export default Gifts
