
















































































import { Component, Inject, Mixins, Prop } from 'vue-property-decorator'

import { CartItemData, ProductData } from '../../../../../contexts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { CartViewMixin } from '../../../../shared/mixins/cartView.mixin'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import { CartCoupons } from '../../../organisms/CartCoupons'
import { CartSummary } from '../../../organisms/CartSummary'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItemEnhanced } from '../../../molecules/CartItem/CartItem.helpers'

import { Gifts } from '../partials/Gifts.vue'
import { PaymentOptions } from '../partials/PaymentOptions.vue'
import { PredictedDelivery } from '../partials/PredictedDelivery.vue'
import { CarouselConfig, CartViewConfig } from '../Cart.config'
import SuggestedProducts from '../partials/SuggestedProducts.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component<Enhanced>({
  name: 'Enhanced',
  components: {
    CartCoupons,
    CartSummary,
    Gifts,
    PaymentOptions,
    PredictedDelivery,
    SidesLayout,
    SingleCartItem,
    FullscreenLoader,
    SuggestedProducts
  },
  mounted (): void {
    const getFirstVariant = (item: CartItemData) => {
      return Object.values(item.product.variants)[0]
    }
    const getFirstVariantKey = (item: CartItemData) => {
      const key = Object.keys(item.product.variants)[0] ?? ''
      return (key !== '_') ? key : ''
    }

    if (this.cart) {
      this.eventBus.emit(
        'app:cart.view',
        {
          currency: this.cart.total.currency,
          value: this.cart.total.value,
          coupon: (this.cart.coupons[0]) ? this.cart.coupons[0].code : '',
          items: this.cart.items.map((item) => {
            const variant = getFirstVariant(item)
            const variantSlug = getFirstVariantKey(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              variant: variantSlug,
              name: variant.name,
              currency: this.cart?.total.currency,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: item.product.variants && (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )
    }
  }

})
export class Enhanced extends Mixins<IToastMixin, IBaseCart, CartViewMixin>(
  ToastMixin,
  BaseCartMixin,
  CartViewMixin
) {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  protected readonly config!: CartViewConfig

  @Prop({ type: Array, required: false })
  public readonly suggestedProducts?: Array<ProductData>

  public get cartItems () {
    return this.cart?.items.filter((item) => !item.isGift)
  }

  public get gifts () {
    return this.cart?.items.filter((item) => item.isGift)
  }

  public get paymentIcons (): string[] {
    if (!this.config.hasOwnProperty('cartIcons')) {
      return []
    }

    if (this.config.cartIcons.length === 0) {
      return []
    }

    return this.config.cartIcons
  }

  public get carouselConfig (): CarouselConfig {
    return this.config.carouselConfig
  }

  public get showEnhancedCouponMobile (): boolean {
    if (!this.config.hasOwnProperty('showEnhancedCouponMobile')) {
      return false
    }

    return this.config.showEnhancedCouponMobile
  }

  public onGiftResign (): void {
    this.cart?.items.filter((item) => !item.isGift)
  }

  public onGiftSelect (value: { id: string }): void {
    const selectedGift = this.gifts?.filter((item) => item.product.id === value.id)

    const cartItems = this.cart?.items.filter((item) => !item.isGift)

    if (this.cart && cartItems && selectedGift) {
      this.cart.items = cartItems?.concat(selectedGift)
    }
  }

  public translateToCartItem = translateToCartItemEnhanced
}

export default Enhanced
