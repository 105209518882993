

















import { Component, Mixins } from 'vue-property-decorator'

import { monthNamesRegistry } from '../../../../shared/contracts/months'
import { DeliveryDateMixin, IDeliveryDate } from '../../../../shared/mixins/delivery-date.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PredictedDelivery>({
  name: 'PredictedDelivery'
})
export class PredictedDelivery extends Mixins<IDeliveryDate>(DeliveryDateMixin) {
  public get deliveryDateTimeDesc (): string {
    if (!this.hasTimer) {
      return ''
    }

    const deliveryWeekday = this.getDeliveryWeekdayLabel()

    return this.$t('front.checkout.views.CartView.enhanced.deliveryDescription', {
      time: this.MAX_ORDER_HOUR + ':00',
      day: deliveryWeekday.toLowerCase()
    }).toString()
  }

  protected formatDate (date: Date): string {
    const month = date.getMonth()
    const day = date.getDate()
    const weekday = this.getWeekday(date)

    const localePath = 'front.shared.mixins.deliveryDateMixin'
    const weekdayString = this.$t(`${localePath}.days.${weekday}`)
    const monthString = this.$t(`${localePath}.months.${monthNamesRegistry[month]}`)

    return `${weekdayString}, ${day} ${monthString}`
  }
}

export default PredictedDelivery
